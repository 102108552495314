import "./index.scss";

import { Button, Flex, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SvgIcon } from "@/shared/icons";

export const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.language);

  // const handleLanguageChange = async (lang: string) => {
  //   await i18n.changeLanguage(lang);
  //   setSelectedLanguage(lang);
  // };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");

    if (storedLanguage) {
      i18n
        .changeLanguage(storedLanguage)
        .then(() => {
          setSelectedLanguage(storedLanguage);
        })
        .catch((error) => {
          console.error("Failed to change language:", error);
        });
    }
  }, [i18n]);

  const handleLanguageChange = (lang: string) => {
    i18n
      .changeLanguage(lang)
      .then(() => {
        setSelectedLanguage(lang);
        localStorage.setItem("selectedLanguage", lang);
      })
      .catch((error) => {
        console.error("Failed to change language:", error);
      });
  };

  return (
    <Flex className="language-switch-wrapper" align="flex-end" gap={5}>
      <SvgIcon type="planet" width={24} height={24} className="language-switch-icon" />
      <Flex className="language-switch" align="center">
        <Button
          className="language-switch-btn"
          style={{ color: selectedLanguage === "en" ? "#A5A7AC" : "#6E6F73" }}
          onClick={() => handleLanguageChange("en")}
        >
          ENG
        </Button>
        <Typography.Text className="language-switch-slash">/</Typography.Text>
        <Button
          className="language-switch-btn"
          style={{ color: selectedLanguage === "fr" ? "#A5A7AC" : "#6E6F73" }}
          onClick={() => handleLanguageChange("fr")}
        >
          FR
        </Button>
      </Flex>
    </Flex>
  );
};

import { CSSProperties, FC } from "react";

import { SvgIcon } from "@/shared/icons";

interface LogoProps {
  size?: "short" | "long";
  width?: number | string;
  height?: number | string;
  style?: CSSProperties;
}

const Logo: FC<LogoProps> = ({ size, width: customWidth, height: customHeight, style: customStyle, ...props }) => {
  return size === "short" ? (
    <SvgIcon
      type="logoShort"
      width={customWidth || 20}
      height={customHeight || 24}
      style={{ flexShrink: 0, ...customStyle }}
      {...props}
    />
  ) : (
    <SvgIcon
      type="logo"
      width={customWidth || 90}
      height={customHeight || 24}
      style={{ flexShrink: 0, ...customStyle }}
      {...props}
    />
  );
};

export { Logo };

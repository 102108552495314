import "./index.scss";

import { Flex, Menu, MenuProps, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { SvgIcon } from "@/shared/icons";

type MenuItem = Required<MenuProps>["items"][number];

export const HeaderNavMenu = () => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState("Home");

  const items: MenuItem[] = [
    {
      label: (
        <Link to="/" className="menu-link">
          {t("header.home")}
        </Link>
      ),
      key: "Home",
    },
    {
      label: (
        <Link to={"#"} className="menu-link">
          {t("header.solutions")}
        </Link>
      ),
      key: "Solutions",
    },
    {
      label: (
        <Flex align="center" gap={4}>
          <Typography.Paragraph className="menu-link">{t("header.our_company")}</Typography.Paragraph>
          <SvgIcon type="arrowTriangle" width={24} height={24} />
        </Flex>
      ),
      key: "Our Company",
      children: [
        {
          label: (
            <Link to={"#"} className="menu-link">
              {t("header.option_1")}
            </Link>
          ),
          key: "Option 1",
        },
        {
          label: (
            <Link to={"#"} className="menu-link">
              {t("header.option_2")}
            </Link>
          ),
          key: "Option 2",
        },
      ],
    },
    {
      label: (
        <Link to={"#"} className="menu-link">
          {t("header.contact")}
        </Link>
      ),
      key: "Contact",
    },
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
  };

  return (
    <Menu className="header-nav-menu" mode="horizontal" selectedKeys={[current]} items={items} onClick={onClick} />
  );
};

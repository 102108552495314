import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import EnTranslation from "@/shared/locales/en/en.json";
import FrTranslation from "@/shared/locales/fr/fr.json";

const storedLanguage = localStorage.getItem("selectedLanguage") || "en";

i18next
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: storedLanguage,
    resources: {
      en: {
        translation: EnTranslation,
      },
      fr: {
        translation: FrTranslation,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(console.error);

export default i18next;

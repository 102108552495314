import "@/shared/locales/i18n";
import "./index.scss";

import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ConfigProvider } from "antd";
import { createContext, type FC, memo, type PropsWithChildren, Suspense, useContext, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { checkAuth } from "@/shared/api";
import { useAuthStore } from "@/store/auth";
import { getAuthToken } from "@/utils/auth";

import { version } from "../../../package.json";
import introspection from "../../graphql";
import routes from "../../pages";
import theme from "../../themes";

const url = import.meta?.env?.WEBSITE_API_URL;

type ContextProps = {
  app: {
    version: string;
    lang: { language: string | null; setLanguage: (value: string) => void };
  };
};

const app: ContextProps["app"] = {
  version,
  lang: {
    language: "en",
    setLanguage: () => {},
  },
};

const Context = createContext({ app });

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  return <Context.Provider value={{ ...props }}>{children}</Context.Provider>;
};

const httpLink = createHttpLink({
  uri: `${url ?? "http://localhost:1337/graphql"}`,
  credentials: "same-origin",
});

const authLink = setContext((_, { headers }) => {
  const accessToken = getAuthToken();

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (
    graphQLErrors?.find(({ extensions }) => extensions.code == "FORBIDDEN") ||
    networkError?.message?.includes("401")
  ) {
    throw new Error("Unathorized");
    // TODO: add proper handling of Unathorized access
  }

  return forward(operation);
});

const useApp: () => ContextProps = () => useContext(Context);

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
});

const App: FC = () => {
  const userLoggedIn = useAuthStore((store) => store.loggedIn);
  const setStore = useAuthStore((store) => store.set);

  const router = createBrowserRouter(routes(userLoggedIn));

  const initialAuth = async () => {
    try {
      let response: any = await checkAuth(getAuthToken());
      response = await response.json();
      setStore((store) => {
        console.log(response);
        store.loggedIn = true;
        store.user = response as UsersPermissionsMe;
      });
    } catch (error) {
      console.log(`---`, error);
    }
  };

  useEffect(() => {
    initialAuth().catch(console.error);
  }, []);

  return (
    <ApolloProvider client={client}>
      <ContextProvider app={app}>
        <HelmetProvider>
          <Suspense>
            <ConfigProvider theme={theme}>
              <RouterProvider router={router} />
            </ConfigProvider>
          </Suspense>
        </HelmetProvider>
      </ContextProvider>
    </ApolloProvider>
  );
};

export default memo(App);
export { useApp };

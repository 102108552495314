import "./index.scss";

import { Menu } from "antd";
import { useTranslation } from "react-i18next";

import { SvgIcon } from "@/shared/icons";

type MenuItem = {
  label: string;
  icon: JSX.Element;
  key: string;
};

type SidebarNavMenuProps = {
  currentMenuItem: string;
  onMenuItemClick: (e: { key: React.Key }) => void;
};

export const SidebarNavMenu = ({ currentMenuItem, onMenuItemClick }: SidebarNavMenuProps) => {
  const { t } = useTranslation();

  const items: MenuItem[] = [
    {
      label: t("sidebar.projects"),
      icon: <SvgIcon type="projects" className="sidebar-icon" />,
      key: "projects",
    },
    {
      label: t("sidebar.service_calls"),
      icon: <SvgIcon type="serviceCalls" className="sidebar-icon" />,
      key: "service-calls",
    },
    {
      label: t("sidebar.all_tasks"),
      icon: <SvgIcon type="tasks" className="sidebar-icon" />,
      key: "tasks",
    },
    {
      label: t("sidebar.customers"),
      icon: <SvgIcon type="customers" className="sidebar-icon" />,
      key: "customers",
    },
    {
      label: t("sidebar.invoices"),
      icon: <SvgIcon type="invoices" className="sidebar-icon" />,
      key: "invoices",
    },
    {
      label: t("sidebar.team"),
      icon: <SvgIcon type="team" className="sidebar-icon" />,
      key: "team",
    },
    {
      label: t("sidebar.timesheets"),
      icon: <SvgIcon type="timesheets" className="sidebar-icon" />,
      key: "timesheets",
    },
  ];

  return (
    <Menu
      className="sidebar-nav-menu"
      mode="inline"
      items={items}
      selectedKeys={[currentMenuItem]}
      onClick={onMenuItemClick}
    />
  );
};

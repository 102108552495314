import "./index.scss";

import { Avatar, Menu, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { SvgIcon } from "@/shared/icons";
import ProfileImg from "@/shared/images/profile-example.png";

type MenuItem = {
  label: string | JSX.Element;
  icon?: JSX.Element;
  key: string;
};

type SidebarBottomMenuProps = {
  collapsed: boolean;
  currentMenuItem: string;
  onMenuItemClick: (e: { key: React.Key }) => void;
};

export const SidebarBottomMenu = ({ collapsed, currentMenuItem, onMenuItemClick }: SidebarBottomMenuProps) => {
  const { t } = useTranslation();

  const items: MenuItem[] = [
    {
      label: t("sidebar.notifications"),
      icon: <SvgIcon type="notifications" className="sidebar-icon" />,
      key: "notifications",
    },
    {
      label: "Jonathan A.",
      icon: <Avatar src={ProfileImg} size="small" />,
      key: "profile",
    },
    {
      label: (
        <Typography.Paragraph className="role">
          {collapsed ? t("sidebar.executive").slice(0, 2) : t("sidebar.executive")}
        </Typography.Paragraph>
      ),
      key: "role",
    },
  ];

  return (
    <Menu
      className="sidebar-bottom-menu"
      mode="inline"
      items={items}
      selectedKeys={[currentMenuItem]}
      onClick={onMenuItemClick}
    />
  );
};

import ky from "ky";

const API_URL = `${import.meta.env.WEBSITE_API_BASE_URL ?? "http://localhost:1337"}/api`;

// const AUTH_PATH = "/auth/local";
// const ME_PATH = "/users/me";

const userPath = (userId: string | null = null) => `${API_URL}/users/${userId || "me"}`;

export function checkAuth(token: string | null) {
  return ky(userPath(), {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  });
}

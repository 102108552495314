export function getAuthToken(): string | null {
  return localStorage.getItem("nx-auth");
}

export function setAuthToken(token: string | null): void {
  if (!token) {
    localStorage.removeItem("nx-auth");
  } else {
    localStorage.setItem("nx-auth", token);
  }
}

import { Layout as BaseLayout } from "antd";
import { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Loader } from "@/components/ui/Loader";

import { Sidebar } from "./ui/Sidebar";

export const RegisteredLayout: FC = () => {
  return (
    <BaseLayout hasSider style={{ position: "relative", height: "100vh", overflow: "hidden" }}>
      <Sidebar />
      <BaseLayout.Content style={{ width: "100%", padding: "0 auto", overflowY: "auto" }}>
        <Suspense fallback={<Loader size="large" style={{ padding: "50vh" }} />}>
          <Outlet />
        </Suspense>
      </BaseLayout.Content>
    </BaseLayout>
  );
};

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BankInfo",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "Customer",
      "CustomerLocation",
      "EmailTemplate",
      "Home",
      "I18NLocale",
      "ImportantNumber",
      "Invoice",
      "InvoiceLaborSummary",
      "InvoiceMaterialSummary",
      "InvoiceSummaryByTask",
      "InvoiceTransportationSummary",
      "LaborSummaryByTask",
      "Layout",
      "Material",
      "MaterialSummaryByTask",
      "Message",
      "Project",
      "ReactIconsIconlibrary",
      "Task",
      "TaskSummary",
      "Timesheet",
      "Transportation",
      "TransportationConstant",
      "TransportationSummaryByTask",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BankInfo",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "Customer",
      "CustomerLocation",
      "EmailTemplate",
      "Home",
      "I18NLocale",
      "ImportantNumber",
      "Invoice",
      "InvoiceLaborSummary",
      "InvoiceMaterialSummary",
      "InvoiceSummaryByTask",
      "InvoiceTransportationSummary",
      "LaborSummaryByTask",
      "Layout",
      "Material",
      "MaterialSummaryByTask",
      "Message",
      "Project",
      "ReactIconsIconlibrary",
      "Task",
      "TaskSummary",
      "Timesheet",
      "Transportation",
      "TransportationConstant",
      "TransportationSummaryByTask",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    ${LinkFragmentDoc}
${CardFragmentDoc}`;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    ${CardFragmentDoc}`;
 const AddCommentToTaskDocument = gql`
    mutation AddCommentToTask($input: AddCommentToTaskInput!) {
  addCommentToTask(input: $input) {
    commentAdded
  }
}
    `;
export type AddCommentToTaskMutationFn = Apollo.MutationFunction<AddCommentToTaskMutation, AddCommentToTaskMutationVariables>;
export function useAddCommentToTaskMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentToTaskMutation, AddCommentToTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentToTaskMutation, AddCommentToTaskMutationVariables>(AddCommentToTaskDocument, options);
      }
export type AddCommentToTaskMutationHookResult = ReturnType<typeof useAddCommentToTaskMutation>;
export type AddCommentToTaskMutationResult = Apollo.MutationResult<AddCommentToTaskMutation>;
 const ApproveItemsDocument = gql`
    mutation ApproveItems($input: VerifyItemInput!) {
  approveItems(input: $input) {
    approved
  }
}
    `;
export type ApproveItemsMutationFn = Apollo.MutationFunction<ApproveItemsMutation, ApproveItemsMutationVariables>;
export function useApproveItemsMutation(baseOptions?: Apollo.MutationHookOptions<ApproveItemsMutation, ApproveItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveItemsMutation, ApproveItemsMutationVariables>(ApproveItemsDocument, options);
      }
export type ApproveItemsMutationHookResult = ReturnType<typeof useApproveItemsMutation>;
export type ApproveItemsMutationResult = Apollo.MutationResult<ApproveItemsMutation>;
 const CheckUserPasswordDocument = gql`
    mutation checkUserPassword($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
  }
}
    `;
export type CheckUserPasswordMutationFn = Apollo.MutationFunction<CheckUserPasswordMutation, CheckUserPasswordMutationVariables>;
export function useCheckUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<CheckUserPasswordMutation, CheckUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckUserPasswordMutation, CheckUserPasswordMutationVariables>(CheckUserPasswordDocument, options);
      }
export type CheckUserPasswordMutationHookResult = ReturnType<typeof useCheckUserPasswordMutation>;
export type CheckUserPasswordMutationResult = Apollo.MutationResult<CheckUserPasswordMutation>;
 const CreateCustomerDocument = gql`
    mutation createCustomer($data: CustomerInput!) {
  createCustomer(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
 const CreateCustomerLocationDocument = gql`
    mutation createCustomerLocation($data: CustomerLocationInput!) {
  createCustomerLocation(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreateCustomerLocationMutationFn = Apollo.MutationFunction<CreateCustomerLocationMutation, CreateCustomerLocationMutationVariables>;
export function useCreateCustomerLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerLocationMutation, CreateCustomerLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerLocationMutation, CreateCustomerLocationMutationVariables>(CreateCustomerLocationDocument, options);
      }
export type CreateCustomerLocationMutationHookResult = ReturnType<typeof useCreateCustomerLocationMutation>;
export type CreateCustomerLocationMutationResult = Apollo.MutationResult<CreateCustomerLocationMutation>;
 const CreateItemsInTaskDetailsDocument = gql`
    mutation createItemsInTaskDetails($input: CreateItemsInput!) {
  createItemsInTaskDetails(input: $input) {
    created
  }
}
    `;
export type CreateItemsInTaskDetailsMutationFn = Apollo.MutationFunction<CreateItemsInTaskDetailsMutation, CreateItemsInTaskDetailsMutationVariables>;
export function useCreateItemsInTaskDetailsMutation(baseOptions?: Apollo.MutationHookOptions<CreateItemsInTaskDetailsMutation, CreateItemsInTaskDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateItemsInTaskDetailsMutation, CreateItemsInTaskDetailsMutationVariables>(CreateItemsInTaskDetailsDocument, options);
      }
export type CreateItemsInTaskDetailsMutationHookResult = ReturnType<typeof useCreateItemsInTaskDetailsMutation>;
export type CreateItemsInTaskDetailsMutationResult = Apollo.MutationResult<CreateItemsInTaskDetailsMutation>;
 const DeleteCommentDocument = gql`
    mutation DeleteComment($input: DeleteCommentInput!) {
  deleteComment(input: $input) {
    deleted
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
 const DeleteCustomerLocationDocument = gql`
    mutation DeleteCustomerLocation($deleteCustomerLocationId: ID!) {
  deleteCustomerLocation(id: $deleteCustomerLocationId) {
    data {
      id
    }
  }
}
    `;
export type DeleteCustomerLocationMutationFn = Apollo.MutationFunction<DeleteCustomerLocationMutation, DeleteCustomerLocationMutationVariables>;
export function useDeleteCustomerLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerLocationMutation, DeleteCustomerLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerLocationMutation, DeleteCustomerLocationMutationVariables>(DeleteCustomerLocationDocument, options);
      }
export type DeleteCustomerLocationMutationHookResult = ReturnType<typeof useDeleteCustomerLocationMutation>;
export type DeleteCustomerLocationMutationResult = Apollo.MutationResult<DeleteCustomerLocationMutation>;
 const DeleteMaterialInTaskDetailsDocument = gql`
    mutation DeleteMaterialInTaskDetails($input: DeleteMaterialInput!) {
  deleteMaterial(input: $input) {
    deleted
  }
}
    `;
export type DeleteMaterialInTaskDetailsMutationFn = Apollo.MutationFunction<DeleteMaterialInTaskDetailsMutation, DeleteMaterialInTaskDetailsMutationVariables>;
export function useDeleteMaterialInTaskDetailsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMaterialInTaskDetailsMutation, DeleteMaterialInTaskDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMaterialInTaskDetailsMutation, DeleteMaterialInTaskDetailsMutationVariables>(DeleteMaterialInTaskDetailsDocument, options);
      }
export type DeleteMaterialInTaskDetailsMutationHookResult = ReturnType<typeof useDeleteMaterialInTaskDetailsMutation>;
export type DeleteMaterialInTaskDetailsMutationResult = Apollo.MutationResult<DeleteMaterialInTaskDetailsMutation>;
 const DeleteTransportationInTaskDetailsDocument = gql`
    mutation DeleteTransportationInTaskDetails($input: DeleteTransportationInput!) {
  deleteTransportation(input: $input) {
    deleted
  }
}
    `;
export type DeleteTransportationInTaskDetailsMutationFn = Apollo.MutationFunction<DeleteTransportationInTaskDetailsMutation, DeleteTransportationInTaskDetailsMutationVariables>;
export function useDeleteTransportationInTaskDetailsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTransportationInTaskDetailsMutation, DeleteTransportationInTaskDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTransportationInTaskDetailsMutation, DeleteTransportationInTaskDetailsMutationVariables>(DeleteTransportationInTaskDetailsDocument, options);
      }
export type DeleteTransportationInTaskDetailsMutationHookResult = ReturnType<typeof useDeleteTransportationInTaskDetailsMutation>;
export type DeleteTransportationInTaskDetailsMutationResult = Apollo.MutationResult<DeleteTransportationInTaskDetailsMutation>;
 const MakeNewInvoiceDocument = gql`
    mutation MakeNewInvoice($input: MakeNewInvoiceInput!) {
  makeNewInvoice(input: $input) {
    noxeId
  }
}
    `;
export type MakeNewInvoiceMutationFn = Apollo.MutationFunction<MakeNewInvoiceMutation, MakeNewInvoiceMutationVariables>;
export function useMakeNewInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<MakeNewInvoiceMutation, MakeNewInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeNewInvoiceMutation, MakeNewInvoiceMutationVariables>(MakeNewInvoiceDocument, options);
      }
export type MakeNewInvoiceMutationHookResult = ReturnType<typeof useMakeNewInvoiceMutation>;
export type MakeNewInvoiceMutationResult = Apollo.MutationResult<MakeNewInvoiceMutation>;
 const MakeNewProjectDocument = gql`
    mutation MakeNewProject($input: MakeNewProjectInput!) {
  makeNewProject(input: $input) {
    isCreated
  }
}
    `;
export type MakeNewProjectMutationFn = Apollo.MutationFunction<MakeNewProjectMutation, MakeNewProjectMutationVariables>;
export function useMakeNewProjectMutation(baseOptions?: Apollo.MutationHookOptions<MakeNewProjectMutation, MakeNewProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeNewProjectMutation, MakeNewProjectMutationVariables>(MakeNewProjectDocument, options);
      }
export type MakeNewProjectMutationHookResult = ReturnType<typeof useMakeNewProjectMutation>;
export type MakeNewProjectMutationResult = Apollo.MutationResult<MakeNewProjectMutation>;
 const MakeNewReportDocument = gql`
    mutation MakeNewReport($input: MakeNewReportInput!) {
  makeNewReport(input: $input) {
    isCreated
  }
}
    `;
export type MakeNewReportMutationFn = Apollo.MutationFunction<MakeNewReportMutation, MakeNewReportMutationVariables>;
export function useMakeNewReportMutation(baseOptions?: Apollo.MutationHookOptions<MakeNewReportMutation, MakeNewReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeNewReportMutation, MakeNewReportMutationVariables>(MakeNewReportDocument, options);
      }
export type MakeNewReportMutationHookResult = ReturnType<typeof useMakeNewReportMutation>;
export type MakeNewReportMutationResult = Apollo.MutationResult<MakeNewReportMutation>;
 const MakeNewServiceCallDocument = gql`
    mutation MakeNewServiceCall($input: MakeNewServiceCallInput!) {
  makeNewServiceCall(input: $input) {
    created
  }
}
    `;
export type MakeNewServiceCallMutationFn = Apollo.MutationFunction<MakeNewServiceCallMutation, MakeNewServiceCallMutationVariables>;
export function useMakeNewServiceCallMutation(baseOptions?: Apollo.MutationHookOptions<MakeNewServiceCallMutation, MakeNewServiceCallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeNewServiceCallMutation, MakeNewServiceCallMutationVariables>(MakeNewServiceCallDocument, options);
      }
export type MakeNewServiceCallMutationHookResult = ReturnType<typeof useMakeNewServiceCallMutation>;
export type MakeNewServiceCallMutationResult = Apollo.MutationResult<MakeNewServiceCallMutation>;
 const MakeNewTaskDocument = gql`
    mutation MakeNewTask($input: MakeNewTaskInput!) {
  makeNewTask(input: $input) {
    isCreated
  }
}
    `;
export type MakeNewTaskMutationFn = Apollo.MutationFunction<MakeNewTaskMutation, MakeNewTaskMutationVariables>;
export function useMakeNewTaskMutation(baseOptions?: Apollo.MutationHookOptions<MakeNewTaskMutation, MakeNewTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeNewTaskMutation, MakeNewTaskMutationVariables>(MakeNewTaskDocument, options);
      }
export type MakeNewTaskMutationHookResult = ReturnType<typeof useMakeNewTaskMutation>;
export type MakeNewTaskMutationResult = Apollo.MutationResult<MakeNewTaskMutation>;
 const PasswordRecoveryDocument = gql`
    mutation passwordRecovery($input: EmailInput!) {
  passwordRecovery(input: $input) {
    isSent
  }
}
    `;
export type PasswordRecoveryMutationFn = Apollo.MutationFunction<PasswordRecoveryMutation, PasswordRecoveryMutationVariables>;
export function usePasswordRecoveryMutation(baseOptions?: Apollo.MutationHookOptions<PasswordRecoveryMutation, PasswordRecoveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordRecoveryMutation, PasswordRecoveryMutationVariables>(PasswordRecoveryDocument, options);
      }
export type PasswordRecoveryMutationHookResult = ReturnType<typeof usePasswordRecoveryMutation>;
export type PasswordRecoveryMutationResult = Apollo.MutationResult<PasswordRecoveryMutation>;
 const UnApproveItemsDocument = gql`
    mutation UnApproveItems($input: VerifyItemInput!) {
  unapproveItems(input: $input) {
    unapproved
  }
}
    `;
export type UnApproveItemsMutationFn = Apollo.MutationFunction<UnApproveItemsMutation, UnApproveItemsMutationVariables>;
export function useUnApproveItemsMutation(baseOptions?: Apollo.MutationHookOptions<UnApproveItemsMutation, UnApproveItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnApproveItemsMutation, UnApproveItemsMutationVariables>(UnApproveItemsDocument, options);
      }
export type UnApproveItemsMutationHookResult = ReturnType<typeof useUnApproveItemsMutation>;
export type UnApproveItemsMutationResult = Apollo.MutationResult<UnApproveItemsMutation>;
 const UnVerifyItemsDocument = gql`
    mutation UnVerifyItems($input: VerifyItemInput!) {
  unverifyItems(input: $input) {
    unverified
  }
}
    `;
export type UnVerifyItemsMutationFn = Apollo.MutationFunction<UnVerifyItemsMutation, UnVerifyItemsMutationVariables>;
export function useUnVerifyItemsMutation(baseOptions?: Apollo.MutationHookOptions<UnVerifyItemsMutation, UnVerifyItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnVerifyItemsMutation, UnVerifyItemsMutationVariables>(UnVerifyItemsDocument, options);
      }
export type UnVerifyItemsMutationHookResult = ReturnType<typeof useUnVerifyItemsMutation>;
export type UnVerifyItemsMutationResult = Apollo.MutationResult<UnVerifyItemsMutation>;
 const UpdateCustomerDocument = gql`
    mutation updateCustomer($id: ID!, $data: CustomerInput!) {
  updateCustomer(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
 const UpdateCustomerLocationDocument = gql`
    mutation UpdateCustomerLocation($id: ID!, $data: CustomerLocationInput!) {
  updateCustomerLocation(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateCustomerLocationMutationFn = Apollo.MutationFunction<UpdateCustomerLocationMutation, UpdateCustomerLocationMutationVariables>;
export function useUpdateCustomerLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerLocationMutation, UpdateCustomerLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerLocationMutation, UpdateCustomerLocationMutationVariables>(UpdateCustomerLocationDocument, options);
      }
export type UpdateCustomerLocationMutationHookResult = ReturnType<typeof useUpdateCustomerLocationMutation>;
export type UpdateCustomerLocationMutationResult = Apollo.MutationResult<UpdateCustomerLocationMutation>;
 const UpdateInvoiceDocument = gql`
    mutation updateInvoice($input: UpdateInvoiceInput!) {
  updateInvoice(input: $input) {
    updated
  }
}
    `;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
 const UpdateItemsInTaskDetailsDocument = gql`
    mutation updateItemsInTaskDetails($input: UpdateItemsInput!) {
  updateItemsInTaskDetails(input: $input) {
    updated
  }
}
    `;
export type UpdateItemsInTaskDetailsMutationFn = Apollo.MutationFunction<UpdateItemsInTaskDetailsMutation, UpdateItemsInTaskDetailsMutationVariables>;
export function useUpdateItemsInTaskDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemsInTaskDetailsMutation, UpdateItemsInTaskDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemsInTaskDetailsMutation, UpdateItemsInTaskDetailsMutationVariables>(UpdateItemsInTaskDetailsDocument, options);
      }
export type UpdateItemsInTaskDetailsMutationHookResult = ReturnType<typeof useUpdateItemsInTaskDetailsMutation>;
export type UpdateItemsInTaskDetailsMutationResult = Apollo.MutationResult<UpdateItemsInTaskDetailsMutation>;
 const UpdateProfilePictureDocument = gql`
    mutation updateProfilePicture($input: Upload!) {
  updateProfilePicture(input: $input) {
    isDone
  }
}
    `;
export type UpdateProfilePictureMutationFn = Apollo.MutationFunction<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>;
export function useUpdateProfilePictureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>(UpdateProfilePictureDocument, options);
      }
export type UpdateProfilePictureMutationHookResult = ReturnType<typeof useUpdateProfilePictureMutation>;
export type UpdateProfilePictureMutationResult = Apollo.MutationResult<UpdateProfilePictureMutation>;
 const UpdateProjectDocument = gql`
    mutation UpdateProject($updateProjectId: ID!, $data: ProjectInput!) {
  updateProject(id: $updateProjectId, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
 const UpdateTaskDocument = gql`
    mutation UpdateTask($input: UpdateTaskInput!) {
  updateTask(input: $input) {
    updated
  }
}
    `;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
 const UpdateTimesheetDocument = gql`
    mutation UpdateTimesheet($updateTimesheetId: ID!, $data: TimesheetInput!) {
  updateTimesheet(id: $updateTimesheetId, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateTimesheetMutationFn = Apollo.MutationFunction<UpdateTimesheetMutation, UpdateTimesheetMutationVariables>;
export function useUpdateTimesheetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimesheetMutation, UpdateTimesheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimesheetMutation, UpdateTimesheetMutationVariables>(UpdateTimesheetDocument, options);
      }
export type UpdateTimesheetMutationHookResult = ReturnType<typeof useUpdateTimesheetMutation>;
export type UpdateTimesheetMutationResult = Apollo.MutationResult<UpdateTimesheetMutation>;
 const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $data: UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
 const VerifyItemsDocument = gql`
    mutation VerifyItems($input: VerifyItemInput!) {
  verifyItems(input: $input) {
    verified
  }
}
    `;
export type VerifyItemsMutationFn = Apollo.MutationFunction<VerifyItemsMutation, VerifyItemsMutationVariables>;
export function useVerifyItemsMutation(baseOptions?: Apollo.MutationHookOptions<VerifyItemsMutation, VerifyItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyItemsMutation, VerifyItemsMutationVariables>(VerifyItemsDocument, options);
      }
export type VerifyItemsMutationHookResult = ReturnType<typeof useVerifyItemsMutation>;
export type VerifyItemsMutationResult = Apollo.MutationResult<VerifyItemsMutation>;
 const CheckUserQueryDocument = gql`
    query checkUserQuery($input: EmailInput!) {
  checkUser(input: $input) {
    isExist
  }
}
    `;
export function useCheckUserQueryQuery(baseOptions: Apollo.QueryHookOptions<CheckUserQueryQuery, CheckUserQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckUserQueryQuery, CheckUserQueryQueryVariables>(CheckUserQueryDocument, options);
      }
export function useCheckUserQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckUserQueryQuery, CheckUserQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckUserQueryQuery, CheckUserQueryQueryVariables>(CheckUserQueryDocument, options);
        }
export type CheckUserQueryQueryHookResult = ReturnType<typeof useCheckUserQueryQuery>;
export type CheckUserQueryLazyQueryHookResult = ReturnType<typeof useCheckUserQueryLazyQuery>;
export type CheckUserQueryQueryResult = Apollo.QueryResult<CheckUserQueryQuery, CheckUserQueryQueryVariables>;
 const CreateMaterialDocument = gql`
    mutation createMaterial($data: MaterialInput!) {
  createMaterial(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreateMaterialMutationFn = Apollo.MutationFunction<CreateMaterialMutation, CreateMaterialMutationVariables>;
export function useCreateMaterialMutation(baseOptions?: Apollo.MutationHookOptions<CreateMaterialMutation, CreateMaterialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMaterialMutation, CreateMaterialMutationVariables>(CreateMaterialDocument, options);
      }
export type CreateMaterialMutationHookResult = ReturnType<typeof useCreateMaterialMutation>;
export type CreateMaterialMutationResult = Apollo.MutationResult<CreateMaterialMutation>;
 const GetAssignedTasksDocument = gql`
    query getAssignedTasks($usersPermissionsUserId: ID) {
  usersPermissionsUser(id: $usersPermissionsUserId) {
    data {
      attributes {
        assignedToTasks {
          data {
            id
            attributes {
              title
              noxeId
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetAssignedTasksQuery(baseOptions?: Apollo.QueryHookOptions<GetAssignedTasksQuery, GetAssignedTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignedTasksQuery, GetAssignedTasksQueryVariables>(GetAssignedTasksDocument, options);
      }
export function useGetAssignedTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignedTasksQuery, GetAssignedTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignedTasksQuery, GetAssignedTasksQueryVariables>(GetAssignedTasksDocument, options);
        }
export type GetAssignedTasksQueryHookResult = ReturnType<typeof useGetAssignedTasksQuery>;
export type GetAssignedTasksLazyQueryHookResult = ReturnType<typeof useGetAssignedTasksLazyQuery>;
export type GetAssignedTasksQueryResult = Apollo.QueryResult<GetAssignedTasksQuery, GetAssignedTasksQueryVariables>;
 const GetCustomersDocument = gql`
    query getCustomers($pagination: PaginationArg, $filters: CustomerFiltersInput) {
  customers(pagination: $pagination, filters: $filters) {
    data {
      id
      attributes {
        email
        laborRate
        name
        paymentTerm
        phoneNumber
        status
        locations {
          data {
            id
            attributes {
              billTo
              shipTo
              site
              truckCharge
            }
          }
        }
      }
    }
    meta {
      pagination {
        page
        pageSize
        total
        pageCount
      }
    }
  }
}
    `;
export function useGetCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
      }
export function useGetCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
        }
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
 const GetInvoiceLaborSummaryDocument = gql`
    query getInvoiceLaborSummary($filters: InvoiceLaborSummaryFiltersInput, $sort: [String], $pagination: PaginationArg) {
  invoiceLaborSummaries(pagination: $pagination, filters: $filters, sort: $sort) {
    data {
      attributes {
        invoiceSummaryByTask {
          data {
            id
          }
        }
        manHours
        laborSellPrice
        timesheets {
          data {
            id
            attributes {
              noxeId
              serviceName
              date
              hoursToInvoice
              laborRate
              laborTotal
              user {
                data {
                  id
                  attributes {
                    username
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetInvoiceLaborSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoiceLaborSummaryQuery, GetInvoiceLaborSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceLaborSummaryQuery, GetInvoiceLaborSummaryQueryVariables>(GetInvoiceLaborSummaryDocument, options);
      }
export function useGetInvoiceLaborSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceLaborSummaryQuery, GetInvoiceLaborSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceLaborSummaryQuery, GetInvoiceLaborSummaryQueryVariables>(GetInvoiceLaborSummaryDocument, options);
        }
export type GetInvoiceLaborSummaryQueryHookResult = ReturnType<typeof useGetInvoiceLaborSummaryQuery>;
export type GetInvoiceLaborSummaryLazyQueryHookResult = ReturnType<typeof useGetInvoiceLaborSummaryLazyQuery>;
export type GetInvoiceLaborSummaryQueryResult = Apollo.QueryResult<GetInvoiceLaborSummaryQuery, GetInvoiceLaborSummaryQueryVariables>;
 const GetInvoiceMaterialSummaryDocument = gql`
    query getInvoiceMaterialSummary($filters: InvoiceMaterialSummaryFiltersInput, $sort: [String], $pagination: PaginationArg) {
  invoiceMaterialSummaries(
    pagination: $pagination
    filters: $filters
    sort: $sort
  ) {
    data {
      attributes {
        invoiceSummaryByTask {
          data {
            id
          }
        }
        materialsDelta
        materialsSellPrice
        materialsTotal
        materials {
          data {
            id
            attributes {
              noxeId
              unitSellPrice
              unitCost
              sellPrice
              itemName
              qty
              unitCost
              delta
              totalItem
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetInvoiceMaterialSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoiceMaterialSummaryQuery, GetInvoiceMaterialSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceMaterialSummaryQuery, GetInvoiceMaterialSummaryQueryVariables>(GetInvoiceMaterialSummaryDocument, options);
      }
export function useGetInvoiceMaterialSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceMaterialSummaryQuery, GetInvoiceMaterialSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceMaterialSummaryQuery, GetInvoiceMaterialSummaryQueryVariables>(GetInvoiceMaterialSummaryDocument, options);
        }
export type GetInvoiceMaterialSummaryQueryHookResult = ReturnType<typeof useGetInvoiceMaterialSummaryQuery>;
export type GetInvoiceMaterialSummaryLazyQueryHookResult = ReturnType<typeof useGetInvoiceMaterialSummaryLazyQuery>;
export type GetInvoiceMaterialSummaryQueryResult = Apollo.QueryResult<GetInvoiceMaterialSummaryQuery, GetInvoiceMaterialSummaryQueryVariables>;
 const GetInvoiceTransportationSummaryDocument = gql`
    query getInvoiceTransportationSummary($filters: InvoiceTransportationSummaryFiltersInput, $sort: [String], $pagination: PaginationArg) {
  invoiceTransportationSummaries(
    pagination: $pagination
    filters: $filters
    sort: $sort
  ) {
    data {
      attributes {
        truckChargeSellPrice
        transportations {
          data {
            id
            attributes {
              distance
              noxeId
              travelMethod
              routeType
              perDiem
              date
              truckCharge
              transportationSellPrice
              user {
                data {
                  id
                  attributes {
                    username
                  }
                }
              }
            }
          }
        }
        invoiceSummByTask {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export function useGetInvoiceTransportationSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoiceTransportationSummaryQuery, GetInvoiceTransportationSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceTransportationSummaryQuery, GetInvoiceTransportationSummaryQueryVariables>(GetInvoiceTransportationSummaryDocument, options);
      }
export function useGetInvoiceTransportationSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceTransportationSummaryQuery, GetInvoiceTransportationSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceTransportationSummaryQuery, GetInvoiceTransportationSummaryQueryVariables>(GetInvoiceTransportationSummaryDocument, options);
        }
export type GetInvoiceTransportationSummaryQueryHookResult = ReturnType<typeof useGetInvoiceTransportationSummaryQuery>;
export type GetInvoiceTransportationSummaryLazyQueryHookResult = ReturnType<typeof useGetInvoiceTransportationSummaryLazyQuery>;
export type GetInvoiceTransportationSummaryQueryResult = Apollo.QueryResult<GetInvoiceTransportationSummaryQuery, GetInvoiceTransportationSummaryQueryVariables>;
 const GetInvoicesDocument = gql`
    query getInvoices($filters: InvoiceFiltersInput, $sort: [String], $pagination: PaginationArg) {
  invoices(pagination: $pagination, filters: $filters, sort: $sort) {
    data {
      id
      attributes {
        noxeId
        owner {
          data {
            attributes {
              username
            }
          }
        }
        status
        issueDate
        dueDate
        totalTaxes
        totalAmount
        subTotal
        tps
        tvq
        transitNumber
        taxesTvq
        taxesTps
        accountNumber
        institutionNumber
        bsp
        rbq
        attachedTasks {
          data {
            attributes {
              noxeId
            }
          }
        }
        messages {
          data {
            id
            attributes {
              user {
                data {
                  attributes {
                    username
                    updatedAt
                    role {
                      data {
                        attributes {
                          name
                        }
                      }
                    }
                    profilePicture {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
              comment
            }
          }
        }
        invoiceSummaryByTasks {
          data {
            id
            attributes {
              descriptionForDisplay
              additionalDescription
              invoiceMaterialSummaries {
                data {
                  attributes {
                    materialsSellPrice
                    materials {
                      data {
                        attributes {
                          sellPrice
                        }
                      }
                    }
                  }
                }
              }
              invoiceLaborSummaries {
                data {
                  attributes {
                    laborSellPrice
                    timesheets {
                      data {
                        attributes {
                          laborTotal
                        }
                      }
                    }
                  }
                }
              }
              invoiceTransportSummaries {
                data {
                  attributes {
                    truckChargeSellPrice
                    transportations {
                      data {
                        attributes {
                          transportationSellPrice
                        }
                      }
                    }
                  }
                }
              }
              task {
                data {
                  id
                  attributes {
                    noxeId
                    title
                    locationSite {
                      data {
                        attributes {
                          billTo
                          shipTo
                          site
                        }
                      }
                    }
                    project {
                      data {
                        attributes {
                          projectName
                        }
                      }
                    }
                    customer {
                      data {
                        attributes {
                          name
                          paymentTerm
                          email
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
      }
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
 const GetLaborDocument = gql`
    query getLabor($filtersByLabour: LaborSummaryByTaskFiltersInput, $filtersByTimesheets: TimesheetFiltersInput, $sort: [String], $pagination: PaginationArg) {
  laborSummaryByTasks(filters: $filtersByLabour) {
    data {
      id
      attributes {
        manHours
        manHoursReady
        manHoursInvoiced
        manHoursPaid
        laborCost
        laborCostReady
        laborCostInvoiced
        laborCostPaid
        laborSellPrice
        laborSellPriceReady
        laborSellPriceInvoiced
        laborSellPricePaid
        laborDelta
        laborDeltaReady
        laborDeltaInvoiced
        laborDeltaPaid
        timesheets(filters: $filtersByTimesheets, sort: $sort, pagination: $pagination) {
          data {
            id
            attributes {
              serviceName
              description
              workingHours
              date
              verified
              laborRate
              approved
              noxeId
              hoursToInvoice
              laborTotal
              status
              user {
                data {
                  id
                  attributes {
                    username
                    burdenRate
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetLaborQuery(baseOptions?: Apollo.QueryHookOptions<GetLaborQuery, GetLaborQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLaborQuery, GetLaborQueryVariables>(GetLaborDocument, options);
      }
export function useGetLaborLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLaborQuery, GetLaborQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLaborQuery, GetLaborQueryVariables>(GetLaborDocument, options);
        }
export type GetLaborQueryHookResult = ReturnType<typeof useGetLaborQuery>;
export type GetLaborLazyQueryHookResult = ReturnType<typeof useGetLaborLazyQuery>;
export type GetLaborQueryResult = Apollo.QueryResult<GetLaborQuery, GetLaborQueryVariables>;
 const GetMaterialsDocument = gql`
    query getMaterials($filtersByMaterial: MaterialFiltersInput, $filtersByTasks: MaterialSummaryByTaskFiltersInput, $sort: [String], $pagination: PaginationArg) {
  materialSummaryByTasks(filters: $filtersByTasks) {
    data {
      id
      attributes {
        materialsCost
        materialsCostReady
        materialsCostInvoiced
        materialsCostPaid
        materialsSellPrice
        materialsSellPriceReady
        materialsSellPriceInvoiced
        materialsSellPricePaid
        materialsDelta
        materialsDeltaReady
        materialsDeltaInvoiced
        materialsDeltaPaid
        materials(sort: $sort, pagination: $pagination, filters: $filtersByMaterial) {
          data {
            id
            attributes {
              itemName
              qty
              oem
              itemProvider
              margin
              sellPrice
              totalItem
              delta
              verified
              unitCost
              status
              noxeId
              marginType
              approved
              unitSellPrice
            }
          }
        }
      }
    }
    meta {
      pagination {
        page
        pageSize
        pageCount
        total
      }
    }
  }
}
    `;
export function useGetMaterialsQuery(baseOptions?: Apollo.QueryHookOptions<GetMaterialsQuery, GetMaterialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMaterialsQuery, GetMaterialsQueryVariables>(GetMaterialsDocument, options);
      }
export function useGetMaterialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaterialsQuery, GetMaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMaterialsQuery, GetMaterialsQueryVariables>(GetMaterialsDocument, options);
        }
export type GetMaterialsQueryHookResult = ReturnType<typeof useGetMaterialsQuery>;
export type GetMaterialsLazyQueryHookResult = ReturnType<typeof useGetMaterialsLazyQuery>;
export type GetMaterialsQueryResult = Apollo.QueryResult<GetMaterialsQuery, GetMaterialsQueryVariables>;
 const GetMessagesDocument = gql`
    query getMessages($filters: MessageFiltersInput, $pagination: PaginationArg) {
  messages(filters: $filters, pagination: $pagination) {
    data {
      id
      attributes {
        comment
        createdAt
        updatedAt
        attachments {
          data {
            attributes {
              url
              name
              hash
              ext
              size
            }
          }
        }
        user {
          data {
            id
            attributes {
              username
              role {
                data {
                  attributes {
                    name
                  }
                }
              }
              profilePicture {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    `;
export function useGetMessagesQuery(baseOptions?: Apollo.QueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, options);
      }
export function useGetMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, options);
        }
export type GetMessagesQueryHookResult = ReturnType<typeof useGetMessagesQuery>;
export type GetMessagesLazyQueryHookResult = ReturnType<typeof useGetMessagesLazyQuery>;
export type GetMessagesQueryResult = Apollo.QueryResult<GetMessagesQuery, GetMessagesQueryVariables>;
 const GetProfileAccountInfoDocument = gql`
    query getProfileAccountInfo {
  me {
    id
    username
    email
    role {
      data {
        id
        attributes {
          name
        }
      }
    }
    phoneNumber
    homeAddress
    emergencyContact
    profilePicture {
      data {
        id
        attributes {
          url
        }
      }
    }
  }
}
    `;
export function useGetProfileAccountInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileAccountInfoQuery, GetProfileAccountInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileAccountInfoQuery, GetProfileAccountInfoQueryVariables>(GetProfileAccountInfoDocument, options);
      }
export function useGetProfileAccountInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileAccountInfoQuery, GetProfileAccountInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileAccountInfoQuery, GetProfileAccountInfoQueryVariables>(GetProfileAccountInfoDocument, options);
        }
export type GetProfileAccountInfoQueryHookResult = ReturnType<typeof useGetProfileAccountInfoQuery>;
export type GetProfileAccountInfoLazyQueryHookResult = ReturnType<typeof useGetProfileAccountInfoLazyQuery>;
export type GetProfileAccountInfoQueryResult = Apollo.QueryResult<GetProfileAccountInfoQuery, GetProfileAccountInfoQueryVariables>;
 const GetProjectAssigneeDocument = gql`
    query getProjectAssignee($filters: UsersPermissionsUserFiltersInput) {
  usersPermissionsUsers(filters: $filters) {
    data {
      id
      attributes {
        username
        role {
          data {
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetProjectAssigneeQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectAssigneeQuery, GetProjectAssigneeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectAssigneeQuery, GetProjectAssigneeQueryVariables>(GetProjectAssigneeDocument, options);
      }
export function useGetProjectAssigneeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectAssigneeQuery, GetProjectAssigneeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectAssigneeQuery, GetProjectAssigneeQueryVariables>(GetProjectAssigneeDocument, options);
        }
export type GetProjectAssigneeQueryHookResult = ReturnType<typeof useGetProjectAssigneeQuery>;
export type GetProjectAssigneeLazyQueryHookResult = ReturnType<typeof useGetProjectAssigneeLazyQuery>;
export type GetProjectAssigneeQueryResult = Apollo.QueryResult<GetProjectAssigneeQuery, GetProjectAssigneeQueryVariables>;
 const GetProjectForTaskDrawerDocument = gql`
    query getProjectForTaskDrawer($filters: ProjectFiltersInput) {
  projects(filters: $filters) {
    data {
      id
      attributes {
        projectDescription
        noxeId
        projectName
        customer {
          data {
            id
            attributes {
              name
              locations {
                data {
                  id
                  attributes {
                    site
                  }
                }
              }
            }
          }
        }
        projectManager {
          data {
            id
            attributes {
              username
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetProjectForTaskDrawerQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectForTaskDrawerQuery, GetProjectForTaskDrawerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectForTaskDrawerQuery, GetProjectForTaskDrawerQueryVariables>(GetProjectForTaskDrawerDocument, options);
      }
export function useGetProjectForTaskDrawerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectForTaskDrawerQuery, GetProjectForTaskDrawerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectForTaskDrawerQuery, GetProjectForTaskDrawerQueryVariables>(GetProjectForTaskDrawerDocument, options);
        }
export type GetProjectForTaskDrawerQueryHookResult = ReturnType<typeof useGetProjectForTaskDrawerQuery>;
export type GetProjectForTaskDrawerLazyQueryHookResult = ReturnType<typeof useGetProjectForTaskDrawerLazyQuery>;
export type GetProjectForTaskDrawerQueryResult = Apollo.QueryResult<GetProjectForTaskDrawerQuery, GetProjectForTaskDrawerQueryVariables>;
 const GetProjectNameByNoxeIdDocument = gql`
    query getProjectNameByNoxeId($filters: ProjectFiltersInput) {
  projects(filters: $filters) {
    data {
      id
      attributes {
        projectName
      }
    }
  }
}
    `;
export function useGetProjectNameByNoxeIdQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectNameByNoxeIdQuery, GetProjectNameByNoxeIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectNameByNoxeIdQuery, GetProjectNameByNoxeIdQueryVariables>(GetProjectNameByNoxeIdDocument, options);
      }
export function useGetProjectNameByNoxeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectNameByNoxeIdQuery, GetProjectNameByNoxeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectNameByNoxeIdQuery, GetProjectNameByNoxeIdQueryVariables>(GetProjectNameByNoxeIdDocument, options);
        }
export type GetProjectNameByNoxeIdQueryHookResult = ReturnType<typeof useGetProjectNameByNoxeIdQuery>;
export type GetProjectNameByNoxeIdLazyQueryHookResult = ReturnType<typeof useGetProjectNameByNoxeIdLazyQuery>;
export type GetProjectNameByNoxeIdQueryResult = Apollo.QueryResult<GetProjectNameByNoxeIdQuery, GetProjectNameByNoxeIdQueryVariables>;
 const GetProjectsDocument = gql`
    query getProjects($filters: ProjectFiltersInput, $sort: [String], $pagination: PaginationArg) {
  projects(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        projectName
        projectDescription
        startDate
        endDate
        customer {
          data {
            id
            attributes {
              name
              email
              phoneNumber
              laborRate
              locations {
                data {
                  id
                  attributes {
                    site
                    billTo
                    shipTo
                    truckCharge
                  }
                }
              }
            }
          }
        }
        tasks {
          data {
            id
            attributes {
              title
              description
              startDate
              endDate
              priority
              locationSite {
                data {
                  id
                  attributes {
                    site
                  }
                }
              }
              assignees {
                data {
                  id
                  attributes {
                    username
                  }
                }
              }
              internalAssignees {
                data {
                  id
                  attributes {
                    username
                  }
                }
              }
              noxeId
              assignees {
                data {
                  id
                }
              }
            }
          }
        }
        projectManager {
          data {
            id
            attributes {
              username
            }
          }
        }
        salesPerson {
          data {
            id
            attributes {
              username
            }
          }
        }
        status
        assignees {
          data {
            id
            attributes {
              username
            }
          }
        }
        noxeId
      }
    }
  }
}
    `;
export function useGetProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
      }
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
 const GetTaskDocument = gql`
    query getTask($taskId: ID) {
  task(id: $taskId) {
    data {
      attributes {
        title
        startDate
        endDate
        customer {
          data {
            attributes {
              name
              laborRate
            }
          }
        }
        project {
          data {
            attributes {
              projectName
            }
          }
        }
        status
        locationSite {
          data {
            attributes {
              site
              truckCharge
            }
          }
        }
        assignees {
          data {
            id
            attributes {
              username
              burdenRate
              profilePicture {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        taskSummary {
          data {
            attributes {
              materialsAll
              materialsReady
              materialsInvoiced
              materialsPaid
              truckChargeAll
              truckChargeReady
              truckChargeInvoiced
              truckChargePaid
              laborAll
              laborReady
              laborInvoiced
              laborPaid
              manHoursAll
              manHoursReady
              manHoursInvoiced
              manHoursPaid
              taskCostAll
              taskCostReady
              taskCostInvoiced
              taskCostPaid
              taskSellPriceAll
              taskSellPriceReady
              taskSellPriceInvoiced
              taskSellPricePaid
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetTaskQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, options);
      }
export function useGetTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, options);
        }
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = Apollo.QueryResult<GetTaskQuery, GetTaskQueryVariables>;
 const GetTasksDocument = gql`
    query getTasks($filters: TaskFiltersInput, $sort: [String], $pagination: PaginationArg) {
  tasks(pagination: $pagination, filters: $filters, sort: $sort) {
    data {
      id
      attributes {
        noxeId
        title
        description
        internalAssignees {
          data {
            id
            attributes {
              username
            }
          }
        }
        locationSite {
          data {
            id
            attributes {
              truckCharge
            }
          }
        }
        endDate
        startDate
        priority
        status
        customer {
          data {
            attributes {
              name
              paymentTerm
              locations {
                data {
                  id
                  attributes {
                    site
                  }
                }
              }
            }
          }
        }
        owner {
          data {
            attributes {
              username
            }
          }
        }
        materials {
          data {
            id
            attributes {
              itemName
              status
              noxeId
              qty
              sellPrice
            }
          }
        }
        transportations {
          data {
            id
            attributes {
              travelMethod
              perDiem
              truckCharge
              status
              noxeId
              user {
                data {
                  id
                  attributes {
                    username
                  }
                }
              }
              status
            }
          }
        }
        timesheets {
          data {
            id
            attributes {
              serviceName
              hoursToInvoice
              status
              noxeId
              laborTotal
              user {
                data {
                  id
                  attributes {
                    username
                  }
                }
              }
              status
            }
          }
        }
        assignees {
          data {
            attributes {
              id
              username
              role {
                data {
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
        project {
          data {
            id
            attributes {
              projectName
              projectManager {
                data {
                  attributes {
                    username
                  }
                }
              }
            }
          }
        }
        assignees {
          data {
            attributes {
              username
            }
          }
        }
        taskTotalCost
        taskSalePrice
        taskTruckChargeTotal
        taskLaborTotal
        taskMaterialTotal
        materialSummaryByTask {
          data {
            id
            attributes {
              materialsCost
              materialsDeltaReady
            }
          }
        }
        laborSummaryByTasks {
          data {
            id
            attributes {
              laborCost
              laborCostReady
            }
          }
        }
        taskSummary {
          data {
            id
            attributes {
              taskCostAll
              taskSellPriceAll
            }
          }
        }
      }
    }
    meta {
      pagination {
        page
        pageSize
        total
        pageCount
      }
    }
  }
}
    `;
export function useGetTasksQuery(baseOptions?: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
      }
export function useGetTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
        }
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<GetTasksQuery, GetTasksQueryVariables>;
 const GetTimesheetDocument = gql`
    query getTimesheet($filters: TimesheetFiltersInput) {
  timesheets(filters: $filters) {
    data {
      id
      attributes {
        serviceName
        description
        workingHours
        date
        task {
          data {
            attributes {
              noxeId
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetTimesheetQuery(baseOptions?: Apollo.QueryHookOptions<GetTimesheetQuery, GetTimesheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimesheetQuery, GetTimesheetQueryVariables>(GetTimesheetDocument, options);
      }
export function useGetTimesheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimesheetQuery, GetTimesheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimesheetQuery, GetTimesheetQueryVariables>(GetTimesheetDocument, options);
        }
export type GetTimesheetQueryHookResult = ReturnType<typeof useGetTimesheetQuery>;
export type GetTimesheetLazyQueryHookResult = ReturnType<typeof useGetTimesheetLazyQuery>;
export type GetTimesheetQueryResult = Apollo.QueryResult<GetTimesheetQuery, GetTimesheetQueryVariables>;
 const TransportationSummaryByTasksDocument = gql`
    query TransportationSummaryByTasks($filterByTransportations: TransportationFiltersInput, $filtersByTasks: TransportationSummaryByTaskFiltersInput, $sort: [String], $pagination: PaginationArg) {
  transportationSummaryByTasks(filters: $filtersByTasks) {
    data {
      id
      attributes {
        transportationCost
        transportationCostReady
        transportationCostInvoiced
        transportationCostPaid
        transportationTruckCharge
        transportationTruckChargeReady
        transportationTruckChargeInvoiced
        transportationTruckChargePaid
        transportationDelta
        transportationDeltaReady
        transportationDeltaInvoiced
        transportationDeltaPaid
        createdAt
        updatedAt
        transportations(
          filters: $filterByTransportations
          sort: $sort
          pagination: $pagination
        ) {
          data {
            id
            attributes {
              travelMethod
              routeType
              date
              perDiem
              truckCharge
              distance
              transportationSellPrice
              verified
              approved
              status
              noxeId
              transportationCost
              delta
              createdAt
              updatedAt
              user {
                data {
                  id
                  attributes {
                    username
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export function useTransportationSummaryByTasksQuery(baseOptions?: Apollo.QueryHookOptions<TransportationSummaryByTasksQuery, TransportationSummaryByTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransportationSummaryByTasksQuery, TransportationSummaryByTasksQueryVariables>(TransportationSummaryByTasksDocument, options);
      }
export function useTransportationSummaryByTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransportationSummaryByTasksQuery, TransportationSummaryByTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransportationSummaryByTasksQuery, TransportationSummaryByTasksQueryVariables>(TransportationSummaryByTasksDocument, options);
        }
export type TransportationSummaryByTasksQueryHookResult = ReturnType<typeof useTransportationSummaryByTasksQuery>;
export type TransportationSummaryByTasksLazyQueryHookResult = ReturnType<typeof useTransportationSummaryByTasksLazyQuery>;
export type TransportationSummaryByTasksQueryResult = Apollo.QueryResult<TransportationSummaryByTasksQuery, TransportationSummaryByTasksQueryVariables>;
 const GetUsersPermissionsUsersDocument = gql`
    query getUsersPermissionsUsers($pagination: PaginationArg, $filters: UsersPermissionsUserFiltersInput) {
  usersPermissionsUsers(pagination: $pagination, filters: $filters) {
    data {
      id
      attributes {
        role {
          data {
            attributes {
              name
            }
          }
        }
        technicianType
        id
        blocked
        username
        email
        phoneNumber
        homeAddress
        burdenRate
        emergencyContact
        createdAt
        updatedAt
      }
    }
    meta {
      pagination {
        page
        pageSize
        total
        pageCount
      }
    }
  }
}
    `;
export function useGetUsersPermissionsUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersPermissionsUsersQuery, GetUsersPermissionsUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersPermissionsUsersQuery, GetUsersPermissionsUsersQueryVariables>(GetUsersPermissionsUsersDocument, options);
      }
export function useGetUsersPermissionsUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersPermissionsUsersQuery, GetUsersPermissionsUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersPermissionsUsersQuery, GetUsersPermissionsUsersQueryVariables>(GetUsersPermissionsUsersDocument, options);
        }
export type GetUsersPermissionsUsersQueryHookResult = ReturnType<typeof useGetUsersPermissionsUsersQuery>;
export type GetUsersPermissionsUsersLazyQueryHookResult = ReturnType<typeof useGetUsersPermissionsUsersLazyQuery>;
export type GetUsersPermissionsUsersQueryResult = Apollo.QueryResult<GetUsersPermissionsUsersQuery, GetUsersPermissionsUsersQueryVariables>;
 const GetUsersPermissionsRolesDocument = gql`
    query getUsersPermissionsRoles {
  usersPermissionsRoles {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useGetUsersPermissionsRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersPermissionsRolesQuery, GetUsersPermissionsRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersPermissionsRolesQuery, GetUsersPermissionsRolesQueryVariables>(GetUsersPermissionsRolesDocument, options);
      }
export function useGetUsersPermissionsRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersPermissionsRolesQuery, GetUsersPermissionsRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersPermissionsRolesQuery, GetUsersPermissionsRolesQueryVariables>(GetUsersPermissionsRolesDocument, options);
        }
export type GetUsersPermissionsRolesQueryHookResult = ReturnType<typeof useGetUsersPermissionsRolesQuery>;
export type GetUsersPermissionsRolesLazyQueryHookResult = ReturnType<typeof useGetUsersPermissionsRolesLazyQuery>;
export type GetUsersPermissionsRolesQueryResult = Apollo.QueryResult<GetUsersPermissionsRolesQuery, GetUsersPermissionsRolesQueryVariables>;
 const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
      }
    }
  }
}
    ${SectionFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
 const MeDocument = gql`
    query me {
  me {
    role {
      data {
        id
        attributes {
          name
        }
      }
    }
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;


export const CREATE_DEPOSIT_MUTATION = gql`
  mutation MakeNewDeposit($input: MakeNewDepositInput!) {
    makeNewDeposit(input: $input) {
      created
    }
  }
`;


export const UpdateInvoiceMaterialSummaryDocument = gql`
  mutation UpdateInvoiceMaterialSummary($id: ID!, $data: InvoiceMaterialSummaryInput!) {
    updateInvoiceMaterialSummary(id: $id, data: $data) {
      data {
        id
        attributes {
          materialsSellPrice
        }
      }
    }
  }
`;

export const UpdateInvoiceLaborSummaryDocument = gql`
  mutation updateInvoiceLaborSummary($id: ID!, $data: InvoiceLaborSummaryInput!) {
    updateInvoiceLaborSummary(id: $id, data: $data) {
      data {
        id
        attributes {
          manHours
        }
      }
    }
  }
`;

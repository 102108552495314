import { Layout as BaseLayout } from "antd";
import { FC, Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Header } from "@/components/layout/ui/Header";
import { Loader } from "@/components/ui/Loader";

export const Layout: FC = () => {
  const { pathname } = useLocation();

  return (
    <BaseLayout style={{ display: "flex", flexDirection: "column", alignItems: "stretch", minHeight: "100vh" }}>
      <BaseLayout.Header
        style={{
          padding: pathname.endsWith("/") ? "24px 120px" : "40px 120px",
          borderBottom: pathname.endsWith("/") ? "1px solid #6E6F73" : "unset",
        }}
      >
        <Header />
      </BaseLayout.Header>
      <BaseLayout style={{ padding: pathname.endsWith("/") ? "0 120px" : "0 124px" }}>
        <Suspense fallback={<Loader size="large" style={{ padding: "50vh" }} />}>
          <Outlet />
        </Suspense>
      </BaseLayout>
    </BaseLayout>
  );
};

import { FC, SVGProps } from "react";

import AddCircle from "/src/shared/icons/add-circle.svg?react";
import AddFile from "/src/shared/icons/add-file.svg?react";
import AlertCircled from "/src/shared/icons/alert_circled.svg?react";
import ArrowDownCircle from "/src/shared/icons/arrow-down-circle.svg?react";
import ArrowDownNoxe from "/src/shared/icons/arrow-down-noxe.svg?react";
import ArrowExpand from "/src/shared/icons/arrow-expand.svg?react";
import ArrowExpandTransparent from "/src/shared/icons/arrow-expand-transparent.svg?react";
import ArrowLeft from "/src/shared/icons/arrow-left.svg?react";
import ArrowTriangle from "/src/shared/icons/arrow-triangle.svg?react";
import BadConnection from "/src/shared/icons/bad-connection.svg?react";
import Calendar from "/src/shared/icons/calendar.svg?react";
import ChevronBackward from "/src/shared/icons/chevron-backward.svg?react";
import ChevronForward from "/src/shared/icons/chevron-forward.svg?react";
import Clip from "/src/shared/icons/clip.svg?react";
import Close from "/src/shared/icons/close.svg?react";
import ClosePrimary from "/src/shared/icons/close-primary.svg?react";
import CloseCircle from "/src/shared/icons/close_circle.svg?react";
import Customer from "/src/shared/icons/customer.svg?react";
import Document from "/src/shared/icons/document.svg?react";
import Dot from "/src/shared/icons/dot.svg?react";
import Download from "/src/shared/icons/download.svg?react";
import Edit from "/src/shared/icons/edit.svg?react";
import EyeClosed from "/src/shared/icons/eye-closed.svg?react";
import EyeOpened from "/src/shared/icons/eye-opened.svg?react";
import FileColor from "/src/shared/icons/file-color.svg?react";
import Filters from "/src/shared/icons/filters.svg?react";
import Gradient from "/src/shared/icons/gradient.svg?react";
import Gradient2 from "/src/shared/icons/gradient2.svg?react";
import Hourglass from "/src/shared/icons/hourglass.svg?react";
import ImgFiller from "/src/shared/icons/img-filler.svg?react";
import Letter from "/src/shared/icons/letter.svg?react";
import Logo from "/src/shared/icons/logo.svg?react";
import LogoShort from "/src/shared/icons/logo-short.svg?react";
import LogoX from "/src/shared/icons/logo-x.svg?react";
import Loupe from "/src/shared/icons/loupe.svg?react";
import MinusOutlined from "/src/shared/icons/minus-outlined.svg?react";
import PaginationNext from "/src/shared/icons/pagination-next.svg?react";
import PaginationNextSuper from "/src/shared/icons/pagination-next-super.svg?react";
import PaginationPrev from "/src/shared/icons/pagination-prev.svg?react";
import PaginationPrevSuper from "/src/shared/icons/pagination-prev-super.svg?react";
import PdfFormat from "/src/shared/icons/pdf-format.svg?react";
import Person from "/src/shared/icons/person.svg?react";
import Planet from "/src/shared/icons/planet.svg?react";
import PlusOutlined from "/src/shared/icons/plus-outlined.svg?react";
import Customers from "/src/shared/icons/sidebar/customers.svg?react";
import Invoices from "/src/shared/icons/sidebar/invoices.svg?react";
import Notifications from "/src/shared/icons/sidebar/notifications.svg?react";
import Projects from "/src/shared/icons/sidebar/projects.svg?react";
import ServiceCalls from "/src/shared/icons/sidebar/service-calls.svg?react";
import SidebarCollapseArrow from "/src/shared/icons/sidebar/sidebar-collapse-arrow.svg?react";
import Tasks from "/src/shared/icons/sidebar/tasks.svg?react";
import Team from "/src/shared/icons/sidebar/team.svg?react";
import Timesheets from "/src/shared/icons/sidebar/timesheets.svg?react";
import StatusCircle from "/src/shared/icons/status-circle.svg?react";
import StepPrevious from "/src/shared/icons/previous-step.svg?react";
import StepNext from "/src/shared/icons/next-step.svg?react";
import StepCurrent from "/src/shared/icons/current-noxe-step.svg?react";
import Summary from "/src/shared/icons/summary.svg?react";
import Trash from "/src/shared/icons/trash.svg?react";
import TwoDots from "/src/shared/icons/two-dots.svg?react";

const icons: { [key: string]: FC<SVGProps<SVGSVGElement>> } = {
  addFile: AddFile,
  addCircle: AddCircle,
  alertCircled: AlertCircled,
  arrowDownNoxe: ArrowDownNoxe,
  arrowDownCircle: ArrowDownCircle,
  arrowExpand: ArrowExpand,
  arrowExpandTransparent: ArrowExpandTransparent,
  arrowLeft: ArrowLeft,
  arrowTriangle: ArrowTriangle,
  badConnection: BadConnection,
  calendar: Calendar,
  chevronBackward: ChevronBackward,
  chevronForward: ChevronForward,
  customers: Customers,
  customer: Customer,
  close: Close,
  closePrimary:ClosePrimary,
  closeCircle: CloseCircle,
  clip: Clip,
  dot: Dot,
  document: Document,
  download: Download,
  edit: Edit,
  eyeOpened: EyeOpened,
  eyeClosed: EyeClosed,
  fileColor: FileColor,
  filters: Filters,
  gradient: Gradient,
  gradient2: Gradient2,
  hourglass: Hourglass,
  invoices: Invoices,
  imgFiller: ImgFiller,
  letter: Letter,
  logo: Logo,
  logoX: LogoX,
  logoShort: LogoShort,
  loupe: Loupe,
  minusOutlined: MinusOutlined,
  notifications: Notifications,
  paginationPrev: PaginationPrev,
  paginationPrevSuper: PaginationPrevSuper,
  paginationNext: PaginationNext,
  paginationNextSuper: PaginationNextSuper,
  pdfFormat: PdfFormat,
  person: Person,
  planet: Planet,
  plusOutlined: PlusOutlined,
  projects: Projects,
  serviceCalls: ServiceCalls,
  sidebarCollapseArrow: SidebarCollapseArrow,
  statusCircle: StatusCircle,
  stepPrevious: StepPrevious,
  stepNext : StepNext,
  stepCurrent: StepCurrent,
  summary: Summary,
  tasks: Tasks,
  team: Team,
  timesheets: Timesheets,
  trash: Trash,
  twoDots: TwoDots,
} as const;

type SvgIconProps = SVGProps<SVGSVGElement> & { type: string };

export const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null;

  return Icon && <Icon {...svgProps} />;
};

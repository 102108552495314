import "./index.scss";

import { Button, Flex } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { HeaderNavMenu } from "@/components/layout/ui/Header/ui/HeaderNavMenu";
import { LanguageSwitch } from "@/components/ui/LanguageSwitch";
import { Logo } from "@/components/ui/Logo";

export const Header: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleConnectClick = () => {
    navigate("/sign-in");
  };

  const handleLogInClick = () => {
    navigate("/sign-in");
  };

  return (
    <Flex className="header-content" align="center">
      <Link to="/" className="logo-link">
        <Logo />
      </Link>
      {pathname.endsWith("/") && (
        <>
          <LanguageSwitch />
          <HeaderNavMenu />
          <Flex gap={8} className="registration-buttons">
            <Button className="log-in-btn" onClick={handleLogInClick}>
              {t("header.log_in")}
            </Button>
            <Button className="btn-thirdly" style={{ minWidth: 173 }} onClick={handleConnectClick}>
              {t("header.connect")}
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
};

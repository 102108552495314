import { Layout as BaseLayout } from "antd";
import { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Loader } from "@/components/ui/Loader";

export const RegisteredLayoutWithoutSider: FC = () => {
  return (
    <BaseLayout style={{ position: "relative", height: "100vh", overflow: "hidden" }}>
      <BaseLayout.Content style={{ width: "100%", padding: "0 auto", overflowY: "auto" }}>
        <Suspense fallback={<Loader size="large" style={{ padding: "50vh 50vw" }} />}>
          <Outlet />
        </Suspense>
      </BaseLayout.Content>
    </BaseLayout>
  );
};

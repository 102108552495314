import { ThemeConfig } from "antd/es/config-provider";

const theme: ThemeConfig = {
  inherit: false,
  token: {
    fontFamily: "'URW Geometric', sans-serif",
    colorText: "#FFF",
  },
  components: {
    Layout: {
      headerPadding: "24px 120px",
      headerBg: "#090C0D",
      bodyBg: "#090C0D",
      footerPadding: "24px 120px",
      footerBg: "#090C0D",
    },
    Menu: {
      fontSize: 18,
      itemPaddingInline: 12,
      itemColor: "#6E6F73",
      lineWidthBold: 2,
      horizontalItemSelectedColor: "#30B783",
      colorBgContainer: "transparent",
      itemSelectedBg: "transparent",
      itemSelectedColor: "#30B783",
      itemActiveBg: "transparent",
    },
    Button: {
      paddingBlock: 16,
      paddingInline: 16,
      borderRadius: 4,
      fontSize: 16,
      fontWeight: 700,
      defaultBg: "transparent",
      defaultHoverBg: "inherit",
      defaultActiveBg: "inherit",
      defaultColor: "#30B783",
      defaultHoverColor: "#03DCA2",
      defaultActiveColor: "#30B783",
      defaultBorderColor: "#30B783",
      defaultHoverBorderColor: "#03DCA2",
      defaultActiveBorderColor: "#30B783",
      defaultShadow: "unset",
      colorPrimary: "#30B783",
      colorPrimaryBorder: "#30B783",
      primaryColor: "#090C0D",
      primaryShadow: "unset",
      defaultGhostColor: "#30B783",
      defaultGhostBorderColor: "transparent",
    },
    Input: {
      paddingBlock: 12,
      paddingInline: 18,
      inputFontSize: 18,
      colorText: "#FFF",
      borderRadius: 4,
      activeBg: "#1F2224",
      hoverBg: "#1F2224",
      colorBorder: "#585B5B",
      hoverBorderColor: "#585B5B",
      activeBorderColor: "#585B5B",
      activeShadow: "unset",
      errorActiveShadow: "transparent",
    },
    InputNumber: {
      paddingBlock: 12,
      paddingInline: 18,
      activeShadow: "unset",
    },
    Select: {
      colorBgElevated: "#131515",
      colorBgContainer: "#1F2224",
      colorBorder: "#1F2224",
      borderRadius: 4,
      fontSize: 18,
      lineHeight: 1,
      optionActiveBg: "#1F2224",
      optionFontSize: 18,
      optionPadding: "12px 18px",
      multipleItemBg: "#1F2224",
    },
    Table: {
      borderRadiusLG: 4,
      cellPaddingBlock: 12,
      cellPaddingInline: 20,
      headerBg: "#131515",
      footerBg: "transparent",
      colorBgContainer: "#1B1D1F",
      rowExpandedBg: "#090C0D",
    },
    Modal: {
      borderRadiusXS: 10,
      borderRadiusSM: 10,
      borderRadiusLG: 10,
      contentBg: "#090C0D",
      colorBgMask: "rgba(255, 255, 255, 0.05)",
      headerBg: "inherit",
    },
    Drawer: {
      colorBgElevated: "#090C0D",
      colorBgMask: "rgba(255, 255, 255, 0.05)",
    },
    DatePicker: {
      activeBg: "#1F2224",
      activeBorderColor: "#585B5B",
      activeShadow: "none",
      colorBgContainer: "#1F2224",
      colorBgElevated: "#1F2224",
      colorBorder: "#585B5B",
    },
    Calendar: {
      fullBg: "#1F2224",
    },
    Collapse: {
      contentBg: "transparent",
      contentPadding: "24px 0",
      headerPadding: "24px 0",
      borderRadiusLG: 1,
      fontSize: 18,
      colorTextHeading: "#30B783",
    },
    Badge: {
      textFontSize: 12,
    },
    Checkbox: {
      colorPrimary: "transparent",
      colorPrimaryHover: "transparent",
      colorPrimaryBorder: "transparent",
      colorBgContainer: "transparent",
      borderRadiusSM: 1,
    },
    Tooltip: {
      borderRadius: 10,
      colorBgSpotlight: "#131313",
    },
  },
};

export default theme;
